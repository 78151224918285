import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20')
];

export const server_loads = [0];

export const dictionary = {
		"/": [2],
		"/admin": [~3],
		"/cheatsheet": [4],
		"/contact": [5],
		"/events": [6],
		"/guide": [7],
		"/guide/[slug]": [8],
		"/installation": [9],
		"/login": [~10],
		"/privacy": [11],
		"/questions": [12],
		"/signup": [~13],
		"/signup/success": [14],
		"/user/account": [~15],
		"/user/activate-account/[key]": [~16],
		"/user/delete-account": [~17],
		"/user/forgot-password": [18],
		"/user/reset-password/[key]": [~19],
		"/workspaces": [~20]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';